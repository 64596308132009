import React from "react";
import { Link } from "react-router-dom";
import {useGlobalContext} from '../context';

export default function Navbar() {
  const {page, setPage} = useGlobalContext();

  return (
    <nav className="navbar">
      <div className="nav-center">
        <Link to="/" >
          <h3>The Cocktail Bar</h3>
        </Link>
        <ul className="nav-links">
          { page !== 1 &&
           <li>
           <Link to="/" >home</Link>
           </li>
          }
          { page !== 2 &&
           <li>
           <Link to="/orders">orders</Link>
           </li>
          }
          { page !== 3 &&
            <li>
            <Link to="/favorites">favorites</Link>
            </li>  
          } 
          { page !== 4 &&
           <li>
           <Link to="/about" >about</Link>
           </li>
          } 
        </ul>
      </div>
    </nav>
  );
}
//document.getElementById("jump_to_this_location").scrollIntoView({behavior: 'smooth'});