import React from 'react'
import { CiCircleRemove } from "react-icons/ci";
import { CiSquareRemove } from "react-icons/ci";
import { FaFirstOrder } from "react-icons/fa";
import { MdFavoriteBorder } from "react-icons/md";
import { useGlobalContext } from "../context";
import { FcViewDetails } from "react-icons/fc";
import PopUpJK, {T} from '../PopUpJK';

export default function Cocktail({ image, name, id, price, info, glass}) {

  const {favorites, setFavorites, orders, setOrders, ps, setPs } = useGlobalContext();

  const handleFavorites = (name, id, opt) => {
    if (opt === 'to') {
    if(! favorites.includes(id)) {
      setFavorites([...favorites, id]);
    }
    } else if (opt === 'from') {
    if(favorites.includes(id)) {
      let lessFavs = favorites.filter(f => f !== id)
      setFavorites(lessFavs);
    }
    }
  }
 
  const handleOrder = (id, name, price, opt) => {
    const lessOrders = orders.filter(order=> order.id !== id);
    const tmp = orders.filter(order=> order.id === id);
    const newOrder = {id: id, name: name, price:price, number:1};

    if (opt === 'to') {
      if (tmp.length === 0) {
        setOrders([newOrder,...lessOrders]);
      } else {
        tmp[0].number++;
        setOrders([tmp[0],...lessOrders]);
      }
      const msg = `One order of '${name}' added to Orders!`;
      setPs({...ps, option:0, open:true, message:msg });
    } else if (opt === 'from') {
      let msg = `One order of '${name}' removed from Orders!`;
      if (tmp[0].number === 1) {    
        setOrders(lessOrders);
        msg +=`\nNo more of '${tmp[0].name}' in Orders!`;
      } else {
        tmp[0].number--;
        setOrders([tmp[0],...lessOrders]);
      } 
      setPs({...ps, option:0, open:true, message:msg });
    }
  }

  const inFavorites = (id) => favorites.includes(id);

  const inOrders = (id) => {
    const ordArray = orders.filter(order => order.id === id);
    return ordArray.length > 0;
  };

  return (
    <article className='cocktail'>
      
      <div className='img-container'>
        <button style={{cursor:'pointer'}} onClick={() => window.location = `/cocktail/${id}` } >
        <img src={image} alt={name} />
        </button>
      </div>
      <div className='cocktail-footer'>
        <h3>{name}</h3>

        <h4>{glass}</h4>

        <p style={{color: info === 'Alcoholic' ? 'pink' : 'black'}}>{info}</p>
        
        <p>${price}</p>
        
        <button className='btn btn-primary btn-details' onClick={() => handleOrder(id,name, price,'to') } >
          <T r={<FaFirstOrder />} s='Buy one order' />
        </button>
        
        {inOrders(id) &&  
        <>
         &nbsp;    
        <button className='btn btn-primary btn-details' onClick={() => handleOrder(id, name, price, 'from') } >
          <T r={<CiSquareRemove />} s='remove one order' />
        </button>
        </>
        }
        
        &nbsp;
        <button className='btn btn-primary btn-details' onClick={() => window.location = `/cocktail/${id}` } >
          <T r={<FcViewDetails />} s='to Details' />
        </button>
        
        &nbsp;
        {!inFavorites(id) &&       
        <button className='btn btn-primary btn-details' onClick={() => handleFavorites(name, id,'to') } >
          <T r={<MdFavoriteBorder />} s='to Favorites' /> 
        </button>
        }
        
        {inFavorites(id) &&       
        <button className='btn btn-primary btn-details' onClick={() => handleFavorites(name, id, 'from') } >
          <T r={< CiCircleRemove />} s='remove from Favorites' /> 
        </button>
        }
        
        <PopUpJK p={ps} setP={setPs}/>
      </div>
    </article>
  )
}
