import React, {useEffect} from "react";
import {useGlobalContext} from '../context';
import { useNavigate } from "react-router-dom";
import Order from '../components/Order';
import Loading from '../components/Loading';
import { RiArrowTurnBackFill } from "react-icons/ri";
import { MdPayment } from "react-icons/md";
import './Orders.css';
import PopUpJK, {T} from '../PopUpJK';


export default function OrdersList() {
  const {loading,setPage, orders, setOrders, ps, setPs} = useGlobalContext();

  useEffect(()=> {
    setPage(2);
  },[setPage]);

  const GetTotal = orders.reduce((s, order)=>(s + order.price * order.number), 0);

  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }
  //if (orders.length < 1) {
    //return <h2 className="section-title">No order</h2>;
  //}

  function pay() {
    let msg;
    if (orders.length > 0) {
      setOrders([]);
      msg = 'Paid. Thank you.';
    } else {
      msg = 'No order. Nothing to pay for. Thank you.';
    }
    setPs({...ps, option:0, open:true, message:msg });
  };

  return (
    <section className="section">
      <h2 className="section-title">Orders</h2>
      <div className="cocktails-center" style={{display: 'flex', justifyContent:'center'}}>
        <table id="customers">
          <tr>
            <th>item</th>
            <th>price</th>
            <th>-</th>
            <th>number</th>
            <th>+</th>
            <th>subtotal</th>
          </tr>
        {
          orders.map((order) =>
            <Order key={order.id} order={order} />
          )
        }
         <tr>
            <td><b>Total</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><b>$ {GetTotal}</b></td>
        </tr>
      </table>
      </div>
      <div style={{display: 'flex', justifyContent:'center', margin:'40px'}}>
        <button onClick={pay} className='btn btn-primary'><T r={<MdPayment />} s='pay' /></button> &nbsp;
        <button onClick={() => navigate(-1)} className='btn btn-primary'><T r={<RiArrowTurnBackFill />} s='back' /></button>
      </div>
      <PopUpJK p={ps} setP={setPs} />
    </section>
  );
}