import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import pages
import Home from "./pages/Home";
import About from "./pages/About";
import SingleCocktail from "./pages/SingleCocktail";
import Error from "./pages/Error";
import Favorites from './pages/favorites';
import Navbar from "./components/Navbar";
import OrdersList from "./pages/Orders";

function App() {
  
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home  />} />
        <Route path="/orders" element={<OrdersList />} />
        <Route path="/cocktail/:id" element={<SingleCocktail />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Error />}  />
      </Routes>
    </Router>
  );
}

export default App;
