import React from "react";
import { useNavigate } from "react-router-dom";
export default function Error() {
  const navigate = useNavigate();
  return (
    <section className="error-page section">
      <div className="error-container">
        <h1>oops! it's a dead end</h1>
        <button onClick={() => navigate(-1)} className='btn btn-primary'>Back</button>
      </div>
    </section>
  );
}
