import React, {useEffect} from "react";
import Loading from "../components/Loading";
import Cocktail from '../components/Cocktail';
import { useGlobalContext } from "../context";
import { useNavigate } from "react-router-dom";
import { RiArrowTurnBackFill } from "react-icons/ri";
import PopUpJK, {T} from '../PopUpJK';

export default function Favorites() {
    const { cocktails, loading, favorites, setFavorites, setPage } = useGlobalContext();
    
    useEffect(()=> {
      setPage(3);
    },[setPage]);

    const navigate = useNavigate();

    if (loading) {
      return <Loading />;
    }
    if (favorites.length < 1) {
      return <h2 className="section-title">No favorite</h2>;
    }
    return (
      <section className="section">
        <h2 className="section-title">Favorites</h2>
        <div className="cocktails-center">
          {cocktails.map((item) => {
            if(favorites.includes(item.id)) {
                return <Cocktail key={item.id} {...item} favorites={favorites} setFavorites={setFavorites} isInFavorites={true}/>;
            } else {
                return null;
            }
           
          })}
        </div>
        <div style={{display: 'flex', justifyContent:'center'}}>
        <button onClick={() => navigate(-1)} className='btn btn-primary'><T r={<RiArrowTurnBackFill />} s='back' /></button>
        </div>   
      </section>
    );
  }