const aboutText = 
<>
This is a simplified cocktail bar menu program. Check tool tips. <br />
1. The cocktail data comes from "https://www.thecocktaildb.com/api/json/v1/1/<br />
2. You can check the details of, order, and set apart as a favorite, <br />
a cocktail by clicking a button.<br />
3. Can check the collections of orders / favorites by clicking the <br />
proper Nav item in the Navigation bar. <br />
4. Payment is simulated. <br />
5. Search can be made by using Search bar.<br />
</>;

export default aboutText;

