import React, {useEffect} from "react";
import {useGlobalContext} from '../context'; 
import aboutText from "../aboutText";

export default function About() {
  const {setPage} = useGlobalContext();
  useEffect(()=> {
    setPage(4);
  },[]);

  return (
    <section className="section about-section">
      <h1 className="section-title">about us</h1>
      <p>
        {aboutText}
      </p>
    </section>
  );
}
