import React, { useState, useContext, useEffect } from "react";
import { useCallback } from "react";

const url = "https://www.thecocktaildb.com/api/json/v1/1/search.php?s=";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("s");
  const [cocktails, setCocktails] = useState([]);
  const [orders, setOrders] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [page, setPage] = useState(0);
  const [ps, setPs] = useState({option: 0, open:false, message:'', CBO:null, CBX: null, CB:null});
  
  const price = (item) => {
    let sum = 0;
    let keys = Object.keys(item);
    for (let x of keys) {
      if (x.includes('strIngredient') && item[x] !== null) {
        sum++;
      }
    }
    return sum * 2;
  };

  const fetchDrinks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${url}${searchTerm}`);
      const data = await response.json();
      //console.log(data);
      const { drinks } = data;
      if (drinks) {
        const newCocktails = drinks.map((item) => 
          ( 
            {
            id: item.idDrink,
            name: item.strDrink,
            image: item.strDrinkThumb,
            info: item.strAlcoholic,
            glass: item.strGlass,
            price: price(item),
            }
          )
        );
        setCocktails(newCocktails);
      } else {
        setCocktails([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [searchTerm]);
  useEffect(() => {
    fetchDrinks();
  }, [searchTerm, fetchDrinks]);

  return (
    <AppContext.Provider
      value={{ 
        loading, 
        cocktails, 
        searchTerm, 
        setSearchTerm, 
        favorites, 
        setFavorites, 
        page, 
        setPage, 
        orders, 
        setOrders,
        ps, 
        setPs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
