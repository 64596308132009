import React, { useEffect } from 'react'
import CocktailList from '../components/CocktailList'
import SearchForm from '../components/SearchForm'
import {useGlobalContext} from '../context';

export default function Home() {
  const {page, setPage} = useGlobalContext();
  useEffect(()=> {
    setPage(1);
  },[]);
  
  return (
    <main>
      <SearchForm />
      <CocktailList />
    </main>
  )
}
