import {useGlobalContext} from '../context';
import PopUpJK, {T} from '../PopUpJK';

export default function Order({order}) {
    const {orders, setOrders, ps, setPs} = useGlobalContext();

    const IncOrder = () => {
      const lessOrders = orders.filter(ord => ord.id !== order.id);
      let newOrder = {...order};
      newOrder.number++;
      setOrders([newOrder, ...lessOrders]);
      const msg = `One order of '${order.name}' added to Orders!`;
      setPs({...ps, option:0, open:true, message:msg });
    };

    const DecOrder = () => {
        const lessOrders = orders.filter(ord => ord.id !== order.id);
        let newOrder = {...order};
        let  msg = `One order of '${order.name}' removed from Orders!`;
        if (newOrder.number > 1) {
           
            setPs({...ps, option:0, open:true, message:msg });
            newOrder.number--;
            setOrders([newOrder, ...lessOrders]);
        } else {
            
            msg += `\nNo more of '${order.name}' in Orders!`;
            setPs({...ps, option:0, open:true, message:msg });
            setOrders(lessOrders);
        }
       
    };
    
    return (
        <>
          <tr>
            <td>{order.name}</td>
            <td>$ {order.price}</td>
            <td><button onClick={DecOrder} style={{cursor:'pointer'}}><T r={'Dec'} s='decrease the order by one' w={80} /> </button></td>
            <td>{order.number}</td>
            <td><button onClick={IncOrder} style={{cursor:'pointer'}}><T r={'Inc'} s='increase the order by one'  w={80}  /></button></td>
            <td>$ {order.price * order.number}</td>
          </tr>
          <PopUpJK p={ps} setP={setPs} />
        </>
      
    );
}